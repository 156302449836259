<template>
  <svg
    width="287"
    height="51"
    viewBox="0 0 287 51"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M131.096 10.3058V41.4279H97.0457V10.3058H131.096Z"
      fill="#F17100"
    />
    <path
      d="M67.9442 41.4353C76.5404 41.4353 83.5089 34.4667 83.5089 25.8705C83.5089 17.2743 76.5404 10.3058 67.9442 10.3058C59.348 10.3058 52.3794 17.2743 52.3794 25.8705C52.3794 34.4667 59.348 41.4353 67.9442 41.4353Z"
      fill="#4CFD8A"
    />
    <path
      d="M16.2496 10.3057C12.1216 10.3057 8.16264 11.9456 5.24368 14.8646C2.32473 17.7835 0.684875 21.7425 0.684875 25.8705C0.685852 29.1901 1.74763 32.4225 3.71528 35.0961C5.68293 37.7697 8.45348 39.7445 11.6227 40.7324C12.1038 40.8865 12.5926 41.016 13.0869 41.1204C14.1183 41.3295 15.168 41.435 16.2203 41.4353H42.9205V10.3057H16.2496Z"
      fill="#00CAE6"
    />
    <path
      d="M200.203 0.870493V38.7258H192.128V0.870493H200.203Z"
      fill="white"
    />
    <path
      d="M209.5 37.9283C207.704 37.1372 206.284 36.0559 205.242 34.6845C204.221 33.3533 203.617 31.7503 203.504 30.0768H211.497C211.537 30.517 211.672 30.9432 211.893 31.3264C212.113 31.7096 212.414 32.0408 212.774 32.2973C213.53 32.866 214.459 33.1498 215.562 33.1486C216.57 33.1486 217.349 32.9517 217.901 32.5579C218.157 32.3924 218.367 32.1648 218.512 31.8962C218.656 31.6277 218.731 31.327 218.728 31.022C218.728 30.2668 218.334 29.7073 217.546 29.3436C216.759 28.9799 215.485 28.5786 213.724 28.1396C212.119 27.7857 210.54 27.3212 208.998 26.7496C207.736 26.2611 206.615 25.4657 205.737 24.4354C204.823 23.379 204.366 21.9531 204.366 20.1578C204.354 18.6842 204.791 17.242 205.619 16.0227C206.453 14.7787 207.682 13.7941 209.306 13.069C210.93 12.3439 212.859 11.9814 215.095 11.9814C218.403 11.9814 221.009 12.8009 222.913 14.4399C224.817 16.0789 225.913 18.2524 226.199 20.9605H218.728C218.68 20.5365 218.55 20.126 218.343 19.7529C218.136 19.3799 217.857 19.0515 217.522 18.7869C216.844 18.2518 215.955 17.9842 214.853 17.9842C213.908 17.9842 213.183 18.1655 212.678 18.5281C212.437 18.6926 212.242 18.9151 212.11 19.175C211.978 19.4349 211.913 19.7239 211.922 20.0153C211.922 20.7728 212.324 21.3404 213.128 21.718C213.932 22.0956 215.184 22.4732 216.884 22.8508C218.504 23.2506 220.098 23.7478 221.657 24.3398C222.914 24.8498 224.026 25.6613 224.896 26.7027C225.822 27.7903 226.302 29.2475 226.336 31.0741C226.356 32.556 225.9 34.0052 225.036 35.2092C224.169 36.4243 222.924 37.3775 221.303 38.069C219.681 38.7605 217.799 39.108 215.656 39.1115C213.346 39.1057 211.294 38.7113 209.5 37.9283Z"
      fill="white"
    />
    <path
      d="M257.394 11.9762L240.859 50.8705H232.172L238.22 37.4488L227.481 11.9762H236.516L242.597 28.4697L248.645 11.9762H257.394Z"
      fill="white"
    />
    <path
      d="M284.024 14.9785C285.867 16.9777 286.788 19.7263 286.788 23.2244V38.6233H278.754V24.312C278.754 22.5468 278.298 21.1759 277.385 20.1995C276.473 19.223 275.243 18.7348 273.697 18.7348C272.152 18.7348 270.923 19.223 270.01 20.1995C269.097 21.1759 268.641 22.5468 268.641 24.312V38.6233H260.56V12.2611H268.643V15.7569C269.489 14.5684 270.625 13.6167 271.944 12.9926C273.404 12.2992 275.004 11.9514 276.621 11.9762C279.714 11.9762 282.181 12.977 284.024 14.9785Z"
      fill="white"
    />
    <path d="M189.257 12.355V38.7258H181.182V12.355H189.257Z" fill="white" />
    <path
      d="M185.223 8.25808C187.263 8.25808 188.917 6.60431 188.917 4.56429C188.917 2.52426 187.263 0.870493 185.223 0.870493C183.183 0.870493 181.529 2.52426 181.529 4.56429C181.529 6.60431 183.183 8.25808 185.223 8.25808Z"
      fill="white"
    />
    <path
      d="M151.814 0.8705C151.328 0.87027 150.847 0.965829 150.399 1.1517C149.95 1.33758 149.543 1.61012 149.2 1.95372C148.856 2.29732 148.584 2.70522 148.399 3.15408C148.214 3.60294 148.119 4.08393 148.12 4.56951C148.12 5.35754 148.372 6.12491 148.839 6.75969C149.306 7.39448 149.963 7.86346 150.716 8.09824C150.832 8.13299 150.95 8.16427 151.063 8.18859C151.308 8.23829 151.557 8.26332 151.807 8.2633H158.157V0.8705H151.814Z"
      fill="white"
    />
    <path
      d="M160.041 0.870493V38.7258H169.261V8.25808H179.291V0.870493H160.041Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {}
</script>

<style></style>
