<template>
  <div
    class="h-full w-full grid grid-cols-1 lg:grid-cols-10 justify-center items-center"
  >
    <div class="h-full col-span-4 p-10 bg-primary hidden lg:block">
      <LogoIcons />
    </div>
    <Wrapper class="max-w-lg mx-auto col-span-6">
      <div class="w-11/12 md:w-11/12 m-auto">
        <LoginForm />
      </div>
    </Wrapper>
  </div>
</template>

<script>
import LogoIcons from '../../components/Brand/LogoIcons.vue'
import LoginForm from '@/components/Login/LoginForm'

export default {
  components: {
    LogoIcons,
    LoginForm,
  },
  methods: {
    switchLocale() {
      if (this.$i18n.locale === 'en') {
        this.$i18n.locale = 'es'
      } else {
        this.$i18n.locale = 'en'
      }
    },
  },
  computed: {
    displayLocale() {
      let locale = 'EN'
      if (this.$i18n.locale === 'es') {
        locale = 'ES'
      } else {
        locale = 'EN'
      }
      return locale
    },
  },
}
</script>

<style></style>
